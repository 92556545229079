$theme-colors: map-merge($theme-colors, $custom-colors);

$custom-theme-colors-rgb: map-loop($all-colors, to-rgb, "$value");

:root,
[data-bs-theme="light"] {

    @each $color,
    $value in $custom-theme-colors-rgb {
        --#{$prefix}#{$color}-rgb: #{$value};
    }
}

$utilities-colors: $custom-theme-colors-rgb;