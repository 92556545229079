@import "~bootstrap/scss/buttons";

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color =="light" {
            @include button-variant($value,
                $value,
                $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
                $hover-border: shade-color($value, $btn-hover-border-shade-amount),
                $active-background: shade-color($value, $btn-active-bg-shade-amount),
                $active-border: shade-color($value, $btn-active-border-shade-amount));
        }

        @else if $color =="dark" {
            @include button-variant($value,
                $value,
                $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
                $hover-border: tint-color($value, $btn-hover-border-tint-amount),
                $active-background: tint-color($value, $btn-active-bg-tint-amount),
                $active-border: tint-color($value, $btn-active-border-tint-amount));
        }

        @else if $color =="blue" {
            @include button-variant($value,
                $value,
                $color: $white,
                $hover-background: $value,
                $hover-border: $value,
                $hover-color: $white,
                $active-background: $value,
                $active-border: $value,
                $active-color: $white,
                $disabled-background: $gray,
                $disabled-border: $gray,
                $disabled-color: $white,
            );
        }

        @else if $color =="blue2" {
            @include button-variant(transparent,
                $value,
                $color: $value,
                $hover-background: transparent,
                $hover-border: $value,
                $hover-color: $value,
                $active-background: transparent,
                $active-border: $value,
                $active-color: $value
            );
        }

        @else if $color =="green" {
            @include button-variant($lgreen,
                $lgreen,
                $color: $value,
                $hover-background: $lgreen,
                $hover-border: $lgreen,
                $hover-color: $value,
                $active-background: $lgreen,
                $active-border: $lgreen,
                $active-color: $value,
            );
        }
        @else if $color =="green2" {
            @include button-variant($value,
                $value,
                $color: $white,
                $hover-background: $value,
                $hover-border: $value,
                $hover-color: $white,
                $active-background: $value,
                $active-border: $value,
                $active-color: $white,
            );
        }

        @else if $color =="purple" {
            @include button-variant($lpurple,
                $lpurple,
                $color: $value,
                $hover-background: $lpurple,
                $hover-border: $lpurple,
                $hover-color: $value,
                $active-background: $lpurple,
                $active-border: $lpurple,
                $active-color: $value,
            );
        }
        @else if $color =="pink" {
            @include button-variant($lpink,
                $lpink,
                $color: $value,
                $hover-background: $lpink,
                $hover-border: $lpink,
                $hover-color: $value,
                $active-background: $lpink,
                $active-border: $lpink,
                $active-color: $value,
            );
        }
        @else if $color =="white" {
            @include button-variant(transparent,
                $value,
                $color: $value,
                $hover-background: transparent,
                $hover-border: $value,
                $hover-color: $value,
                $active-background: transparent,
                $active-border: $value,
                $active-color: $value,
            );
        }


        @else {
            @include button-variant($value, $value);
        }
    }
}