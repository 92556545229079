@font-face {
    font-family: 'Signika-medium';
    src: url('../fonts/Signika-Medium.eot');
    src: url('../fonts/Signika-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Signika-Medium.woff2') format('woff2'),
        url('../fonts/Signika-Medium.woff') format('woff'),
        url('../fonts/Signika-Medium.ttf') format('truetype'),
        url('../fonts/Signika-Medium.svg#Signika-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Signika-light';
    src: url('../fonts/Signika-Light.eot');
    src: url('../fonts/Signika-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Signika-Light.woff2') format('woff2'),
        url('../fonts/Signika-Light.woff') format('woff'),
        url('../fonts/Signika-Light.ttf') format('truetype'),
        url('../fonts/Signika-Light.svg#Signika-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Signika-bold';
    src: url('../fonts/Signika-Bold.eot');
    src: url('../fonts/Signika-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Signika-Bold.woff2') format('woff2'),
        url('../fonts/Signika-Bold.woff') format('woff'),
        url('../fonts/Signika-Bold.ttf') format('truetype'),
        url('../fonts/Signika-Bold.svg#Signika-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Signika-regular';
    src: url('../fonts/Signika-Regular.eot');
    src: url('../fonts/Signika-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Signika-Regular.woff2') format('woff2'),
        url('../fonts/Signika-Regular.woff') format('woff'),
        url('../fonts/Signika-Regular.ttf') format('truetype'),
        url('../fonts/Signika-Regular.svg#Signika-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Signika-semibold';
    src: url('../fonts/Signika-SemiBold.eot');
    src: url('../fonts/Signika-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Signika-SemiBold.woff2') format('woff2'),
        url('../fonts/Signika-SemiBold.woff') format('woff'),
        url('../fonts/Signika-SemiBold.ttf') format('truetype'),
        url('../fonts/Signika-SemiBold.svg#Signika-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

