$blue: #4da6dd;
$blue2: #564d9b;
$lblue3: #e4ecf6;
$blue3: #406db3;
$blue4: #d6e1f1;
$blue5: #519fd8;
$blue6: #bbd8e9;
$blue7: #75a4d5;

$green: #33a23e;
$lgreen: #ecf0eb;
$green2: #c0d996;
$green3: #2cb29f;
$lgreen3: #e1f3f0;
$green4: #74b738;

$purple: #52266c;
$lpurple: #e6dee9;
$purple2: #4e446e;

$pink: #cb5098;
$lpink: #fbe9f1;

$white: #ffffff;

$gray: #dcdcdc;
$gray2: #dddfe7;
$gray3: #f1f2f5;
$gray4: #b4b4b0;
$gray5: #515665;
$gray6: #eaddee;
$gray7: #d9d8d8;
$gray8: #888888;

$navy: #303a53;

$orange: #fabb10;

$red: #e95743;

$custom-colors: (
    "blue": $blue,
    "blue2": $blue2,
    "green": $green,
    "green2": $green2,
    "purple": $purple,
    "pink": $pink,
    "white": $white,
);
$all-colors: (
    "blue": $blue,
    "blue2": $blue2,
    "lblue3": $lblue3,
    "blue3": $blue3,
    "blue4": $blue4,
    "blue5": $blue5,
    "blue6": $blue6,
    "blue7": $blue7,
    "green": $green,
    "green2": $green2,
    "lgreen": $lgreen,
    "green3": $green3,
    "lgreen3": $lgreen3,
    "green4": $green4,
    "purple": $purple,
    "lpurple": $lpurple,
    "purple2": $purple2,
    "pink": $pink,
    "lpink": $lpink,
    "white": $white,
    "gray": $gray,
    "gray2": $gray2,
    "gray3": $gray3,
    "gray4": $gray4,
    "gray5": $gray5,
    "gray6": $gray6,
    "gray7": $gray7,
    "gray8": $gray8,
    "navy": $navy,
    "orange": $orange,
    "red": $red,
);

$input-placeholder-color: $gray8;
$tooltip-bg: '#A9DBE9';
$tooltip-color: '#ffffff';
$tooltip-arrow-width: 1rem;
$tooltip-arrow-height:  1rem;
$tooltip-border-radius: 5rem;
$tooltip-padding-x: 1rem; 
$tooltip-padding-y:  1rem;
$tooltip-font-size:  1rem;
$tooltip-max-width:  100%;
$tooltip-opacity: 1;




$sm: 160rem; //'640px',
$md: 192rem; //'768px'
$lg: 256rem; //'1024px'
$xl: 320rem; // 1280px
$xxl: 284rem; //' 1536px'


